<template>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <div :class="`in_port in_apply ${className}`">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 apply_h mb-2">
            <h5>{{ cycle.name_lang }}</h5>
            <div class="content_cycle" :id="`content_${cycle.id}`">
              <!-- v-model="check" -->

              <p class="descinline">
                {{ cycle.description_lang | shortDesc(20) }}
              </p>
              <p v-if="cycle.description_lang.length > 20" class="descempty">
                {{ cycle.description_lang }}
              </p>
              <label
                v-if="cycle.description_lang.length > 20"
                for=""
                class="readmore"
                @click="clickMore($event)"
                >{{ $t("Read more") }}</label
              >
            </div>
          </div>
          <div class="col-lg-12 mb-2">
            <div class="date">
              <div class="row">
                <div class="col-sm-6">{{ $t("cycle.Start Date") }}</div>
                <div class="col-sm-6 text-right">{{ cycle.start }}</div>
              </div>
              <hr class="horizontal dark my-3" />
              <div class="row">
                <div class="col-sm-6">{{ $t("cycle.Expire Date") }}</div>
                <div class="col-sm-6 text-right">{{ cycle.expire }}</div>
              </div>
            </div>
          </div>
          <div :class="`more-port  apply_btn col-lg-12 `">
            <button
              v-if="check_order"
              @click="apply_cycle(cycle.id)"
              :class="`button green-btn text-uppercase`"
            >
              {{ $t("customer.apply now") }}
              <!-- {{ check_order }} -->
            </button>
            <div class="button white-btn text-uppercase" v-else>
              {{ $t("customer.already applied") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["cycle", "className", "cycles_done"],
  name: "Cycle",
  data() {
    return {};
  },
  filters: {
    shortDesc(value, limit) {
      if (value.length > limit) {
        return value.substring(0, limit);
      } else {
        return value;
      }
    },
  },
  computed: {
    check_order() {
      return this.cycles_done.includes(this.cycle.id) ? false : true;
    },
  },
  methods: {
    clickMore(e) {
      // var value = document.getElementById("expanded_" + id);
      console.log(e);
      console.log(e.target.className);
      if (e.target.className == "readmore") {
        console.log(e.target.innerText);
        // alert(1);
        e.target.innerText = this.$i18n.t("Read less");
        e.target.className = "readless";
        e.target.parentElement.children[0].className = "";
        e.target.parentElement.children[0].className = "descempty";
        e.target.parentElement.children[1].className = "";
        e.target.parentElement.children[1].className = "descinline";
      } else {
        e.target.innerText = this.$i18n.t("Read more");
        e.target.className = "readmore";
        e.target.parentElement.children[0].className = "descinline";
        e.target.parentElement.children[1].className = "descempty";
        // alert(2);
      }
    },
    apply_cycle(id) {
      this.$emit("apply", id);
    },
  },
  mounted() {
    this.toggleread = "readmore";
    this.name_btn = this.$i18n.t("Read more");
  },
};
</script>
<style scoped>
.descinline {
  display: inline;
}
.descempty {
  display: none;
}
.readmore,
.readless {
  color: #06ab95;
  cursor: pointer;
  margin-left: 6px;
  font-size: 13px;
}
.content_cycle {
  padding-top: 20px;
}
</style>
