<template>
  <div>
    <v-container fluid class="py-8 position-relative">
      <Loading v-if="loading"></Loading>
      <Notify ref="myNotify" :details="message"></Notify>
      <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
        {{ $t("cycle.Cycles") }}
      </h5>
      <div class="PORTFOLIO">
        <div class="container">
          <div class="row">
            <Cycle
              :cycles_done="cycles_done"
              :className="
                cycle_id != null && cycle_id == cycle.id
                  ? 'border-spacific'
                  : ''
              "
              v-for="(cycle, i) in cycles"
              :key="i"
              @apply="apply"
              :cycle="cycle"
            ></Cycle>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Cycle from "../Components/Cycle.vue";
import Notify from "../Components/Old/Notify.vue";
import Loading from "../Components/Loading.vue";
import CustomerService from "../../services/customer.service";
export default {
  name: "Cycles",
  components: {
    Cycle,
    Loading,
    Notify,
  },
  data() {
    return {
      loading: true,
      message: {
        msg: null,
        type: null,
      },
      cycle_id: null,
      cycles: [],
      cycles_done: [],
    };
  },
  methods: {
    get_cycles() {
      console.log("cycle1");
      return CustomerService.get_cycles().then(
        (response) => {
          // console.log(response);
          this.cycles = response.data.cycles;
          this.cycles_done = response.data.customer_cycle;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.message = {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          };
        }
      );
    },
    apply(id) {
      this.$swal({
        title: this.$i18n.t("customer.Are you sure to apply for this?!"),
        text: this.$i18n.t("general.You won't be able to revert this"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("customer.Yes,apply!"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
        // willClose: () => {
        //   alert('heeeeeer')
        // },
      }).then((result) => {
        if (result.value) {
          return CustomerService.apply_order({ cycle_id: id }).then(
            (response) => {
              // console.log(response)
              this.cycles_done.push(id);
              this.$swal
                .fire(
                  this.$i18n.t("customer.The request has been submitted!"),
                  response.data.message,
                  "success"
                )
                .then((r) => {
                  if (r.isConfirmed) {
                    this.$router.push("/orders");
                  }
                });
            },
            (error) => {
              console.log(error);
              this.$swal.fire(
                this.$i18n.t("general.Error"),
                this.$i18n.t("general.There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("customer.Cancelled Order"),
            "error"
          );
        }
      });
    },
  },
  mounted() {
    this.get_cycles();
    // localStorage.setItem('cycle_id',1);
    if (localStorage.hasOwnProperty("cycle_id")) {
      this.cycle_id = localStorage.getItem("cycle_id");
    }
    document.title = this.$i18n.t("cycle.Cycles");
  },
};
</script>
<style lang="css">
hr.horizontal {
  opacity: 0.5;
}
</style>
